
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { useRoute } from "vue-router";
import candidateService from "../services/candidate";
import moment, { Moment } from "moment";
import router from "../router";
import commonService from "../services/common";
import {
  CheckCircleOutlined,
  ArrowLeftOutlined,
  DownloadOutlined,
  InsertRowBelowOutlined,
  CalendarOutlined,
  PhoneOutlined,
  SendOutlined,
  HeartOutlined,
  QuestionOutlined,
  BankOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    ArrowLeftOutlined,
    DownloadOutlined,
    InsertRowBelowOutlined,
    CalendarOutlined,
    PhoneOutlined,
    SendOutlined,
    HeartOutlined,
    CheckCircleOutlined,
    BankOutlined,
    // QuestionOutlined,
    UserOutlined,
  },
  setup() {
    const route = useRoute();
    const refereeId = ref<string>(route.params.id as string);
    const refereeDetails = ref();
    const rdetails = ref(null);
    const questionSec = ref(null);
    const commentsSec = ref(null);
    const pageLoder = ref<boolean>(false);
    const activeI = ref();
    const getRefereeDetails = async () => {
      pageLoder.value = true;
      try {
        const res = await candidateService.getRefereeDetails(refereeId.value);
        if (res.data.data.success) {
          for (const profile of res.data.data.referee.profiles) {
            let index = profile.questions.findIndex((e:any) => e.question === 'Any further comments?');
            if(index == 0) {
              profile.questions.push(profile.questions.shift());
            }
          }
          refereeDetails.value = res.data.data.referee;
        }
        pageLoder.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoder.value = false;
        }
      }
    };
    const backToReference = async () => {
      router.back();
    };
    const goto = async (refName: any) => {
      let element;
      activeI.value = refName;
      if (refName === "rdetails") {
        element = rdetails.value;
      } else if (refName === "questionSec") {
        element = questionSec.value;
      } else {
        element = commentsSec.value;
      }
      const top = element.offsetTop;
      window.scrollTo(0, top);
    };
    const downloadPdf = async () => {
      pageLoder.value = true;
      try {
        const res = await candidateService.getRefereeDetailsPDF(
          refereeId.value
        );
        if (res.data.data.success) {
          window.open(res.data.data.pdf_url);
        }
        pageLoder.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoder.value = false;
        }
      }
    };
    onMounted(async () => {
      activeI.value = "rdetails";
      getRefereeDetails();
    });
    return {
      refereeId,
      pageLoder,
      backToReference,
      refereeDetails,
      rdetails,
      questionSec,
      commentsSec,
      goto,
      activeI,
      moment,
      downloadPdf,
      commonService,
    };
  },
});
